import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faUsers, faCogs, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

export const deviceModelTypes = [
    'door',
    'switch',
    'elevator',
    'traffic_light',
    'smart_device',
    'other_device',
]

export const validateDate = (date, defaultMessage) => {
    if (date.isValid()) return date.format('YYYY/MM/DD')
    return defaultMessage || '-'
}

export const formatDate = (dateString) => {
    const date = new Date(dateString)

    // Extract date components
    const day = String(date.getUTCDate()).padStart(2, '0') // Day of the month with leading zero
    const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-indexed, so we add 1
    const year = String(date.getUTCFullYear()).slice(-2) // Get the last two digits of the year

    // Extract time components
    const hours = String(date.getUTCHours()).padStart(2, '0') // Hours with leading zero
    const minutes = String(date.getUTCMinutes()).padStart(2, '0') // Minutes with leading zero
    const seconds = String(date.getUTCSeconds()).padStart(2, '0'); // Seconds with leading zero
    
    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
}

export const handleDrag = (e, setDragActive) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true)
    } else if (e.type === 'dragleave') {
        setDragActive(false)
    }
}

export const handleDrop = (e, setFieldValue, setDragActive, setImageUrl) => {
    const { files } = e.dataTransfer
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (files && files.length) {
        const imageUrl = URL.createObjectURL(files[0])
        setFieldValue('image', files[0])
        setImageUrl(imageUrl)
    }
}

export const handleFileChange = (event, setFieldValue, setImageUrl) => {
    const file = event.currentTarget.files[0]
    if (file) {
        // You can use URL.createObjectURL to create a temporary URL for the selected image.
        const imageUrl = URL.createObjectURL(file)
        setFieldValue('image', file)
        setImageUrl(imageUrl)
    }
}

export const roleAndDescription = {
    team: {
        observer: 'Observers have read-only access for vehicles, tasks, and setups.',
        member: 'Members have access to create, read, update, and delete vehicles, tasks, setups, and task management.',
        maintainer:
            'Maintainers have the same permissions as members and have access to preset and mission management and ROS setups.',
        admin: 'Admins have the same permissions as maintainers and they also have member administration access.',
        owner: 'Owners have the same permissions as admins and can delete and update critical data.',
    },
    organization: {
        observer: 'Observers have read-only access for vehicles, tasks, and setups.',
        member: 'Members have access to create, read, update, and delete vehicles, tasks, and setups.',
        maintainer:
            'Maintainers have the same permissions as members and have access to ROS setups, vehicle management, form management and script editor.',
        admin: 'Admins have the same permissions as maintainers and they also have member administration access.',
        owner: 'Owners have the same permissions as admins and can delete and update critical data.',
    },
}

export const teamsColours = [
    { bg: '#F9EFFE', text: '#670D95' },
    { bg: '#FFFAEB', text: '#DC6803' },
    { bg: '#EEF4FF', text: '#175CD3' },
    { bg: '#FFF9E1', text: '#5A4313' },
] // Purple, Orange, Blue, Yellow

export const OrgDropDown = ({ currentOrg, setForm, toggle, setOrgOrMember }) => (
    <UncontrolledDropdown>
        <DropdownToggle color="warning mr-3" caret>
            Manage Organisation ({currentOrg?.title || '.....'})
        </DropdownToggle>
        <DropdownMenu>
            <DropdownItem
                onClick={() => {
                    setForm({
                        type: 'new-team',
                    })
                    toggle()
                }}
            >
                <FontAwesomeIcon icon={faUsers} /> New Team
            </DropdownItem>
            <DropdownItem
                onClick={() => {
                    setForm({
                        type: 'member',
                    })
                    setOrgOrMember('User')
                    toggle()
                }}
            >
                <FontAwesomeIcon icon={faUserPlus} /> Add User
            </DropdownItem>
            <DropdownItem
                onClick={() => {
                    setForm({ type: 'org-setting' })
                    toggle()
                }}
            >
                <FontAwesomeIcon icon={faCog} /> Settings
            </DropdownItem>
        </DropdownMenu>
    </UncontrolledDropdown>
)

export const TeamManageDropDown = ({ team, setForm, toggle, setOrgOrMember }) => {
    return (
        <UncontrolledDropdown>
            <DropdownToggle color="warning mr-3" caret>
                Manage {team?.title}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem
                    onClick={() => {
                        setForm({ type: 'member', values: {} })
                        setOrgOrMember('Member')
                        toggle()
                    }}
                >
                    <FontAwesomeIcon icon={faUserPlus} /> Add Member
                </DropdownItem>
                <DropdownItem
                    onClick={() => {
                        setForm({
                            type: 'team',
                        })
                        toggle()
                    }}
                >
                    <FontAwesomeIcon icon={faCogs} /> Team Settings
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export const columnsList = [
    {
        dataField: 'name',
        text: 'Name',
        sort: true,
    },
    {
        dataField: 'type',
        text: 'Membership Type',
        sort: true,
    },
    {
        dataField: 'username',
        text: 'Username',
        sort: true,
    },
    {
        dataField: 'email',
        text: 'Email',
        sort: true,
    },
    {
        dataField: 'action',
        headerStyle: { width: '90px' },
    },
]

export const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
]

export const primaryColor = (customValue) => {
    return { backgroundColor: customValue.primary_color, color: 'white' }
}

export const defaultColor = {
    backgroundColor: '#670D95',
    color: 'white',
}
