export const actionTypes = {
    GET_SETUP_LIST: 'GET_SETUP_LIST',
    GET_SETUP_LIST_FAIL: 'GET_SETUP_LIST_FAIL',
    GET_SETUP_LIST_SUCCESS: 'GET_SETUP_LIST_SUCCESS',
    GET_ORG_SETUP_LIST: 'GET_ORG_SETUP_LIST',
    GET_ORG_SETUP_LIST_FAIL: 'GET_ORG_SETUP_LIST_FAIL',
    GET_ORG_SETUP_LIST_SUCCESS: 'GET_ORG_SETUP_LIST_SUCCESS',
    REMOVE_SETUP: 'REMOVE_SETUP',
    REMOVE_SETUP_FAIL: 'REMOVE_SETUP_FAIL',
    REMOVE_SETUP_SUCCESS: 'REMOVE_SETUP_SUCCESS',
    GET_SETUP: 'GET_SETUP',
    GET_SETUP_FAIL: 'GET_SETUP_FAIL',
    GET_SETUP_SUCCESS: 'GET_SETUP_SUCCESS',
    CREATE_SETUP: 'CREATE_SETUP',
    CREATE_SETUP_FAIL: 'CREATE_SETUP_FAIL',
    CREATE_SETUP_SUCCESS: 'CREATE_SETUP_SUCCESS',
    UPDATE_SETUP: 'UPDATE_SETUP',
    UPDATE_SETUP_FAIL: 'UPDATE_SETUP_FAIL',
    UPDATE_SETUP_SUCCESS: 'UPDATE_SETUP_SUCCESS',
    CREATE_ORG_SETUP: 'CREATE_ORG_SETUP',
    CREATE_ORG_SETUP_FAIL: 'CREATE_ORG_SETUP_FAIL',
    CREATE_ORG_SETUP_SUCCESS: 'CREATE_ORG_SETUP_SUCCESS',
    CREATE_DEVICE_MODEL: 'CREATE_DEVICE_MODEL',
    CREATE_DEVICE_MODEL_FAIL: 'CREATE_DEVICE_MODEL_FAIL',
    CREATE_DEVICE_MODEL_SUCCESS: 'CREATE_DEVICE_MODEL_SUCCESS',
    CREATE_ORG_DEVICE_MODEL: 'CREATE_ORG_DEVICE_MODEL',
    CREATE_ORG_DEVICE_MODEL_FAIL: 'CREATE_ORG_DEVICE_MODEL_FAIL',
    CREATE_ORG_DEVICE_MODEL_SUCCESS: 'CREATE_ORG_DEVICE_MODEL_SUCCESS',
    GET_DEVICE_MODELS: 'GET_DEVICE_MODELS',
    GET_DEVICE_MODELS_FAIL: 'GET_DEVICE_MODELS_FAIL',
    GET_DEVICE_MODELS_SUCCESS: 'GET_DEVICE_MODELS_SUCCESS',
    GET_ORG_DEVICE_MODELS: 'GET_ORG_DEVICE_MODELS',
    GET_ORG_DEVICE_MODELS_FAIL: 'GET_ORG_DEVICE_MODELS_FAIL',
    GET_ORG_DEVICE_MODELS_SUCCESS: 'GET_ORG_DEVICE_MODELS_SUCCESS',
    UPDATE_DEVICE_MODEL: 'UPDATE_DEVICE_MODEL',
    UPDATE_DEVICE_MODEL_FAIL: 'UPDATE_DEVICE_MODEL_FAIL',
    UPDATE_DEVICE_MODEL_SUCCESS: 'UPDATE_DEVICE_MODEL_SUCCESS',
    DELETE_DEVICE_MODEL: 'DELETE_DEVICE_MODEL',
    DELETE_DEVICE_MODEL_FAIL: 'DELETE_DEVICE_MODEL_FAIL',
    DELETE_DEVICE_MODEL_SUCCESS: 'DELETE_DEVICE_MODEL_SUCCESS',
    CREATE_DEVICES: 'CREATE_DEVICES',
    CREATE_DEVICES_FAIL: 'CREATE_DEVICES_FAIL',
    CREATE_DEVICES_SUCCESS: 'CREATE_DEVICES_SUCCESS',
    GET_DEVICES: 'GET_DEVICES',
    GET_DEVICES_FAIL: 'GET_DEVICES_FAIL',
    GET_DEVICES_SUCCESS: 'GET_DEVICES_SUCCESS',
    UPDATE_DEVICES: 'UPDATE_DEVICES',
    UPDATE_DEVICES_FAIL: 'UPDATE_DEVICES_FAIL',
    UPDATE_DEVICES_SUCCESS: 'UPDATE_DEVICES_SUCCESS',
    DELETE_DEVICES: 'DELETE_DEVICES',
    DELETE_DEVICES_FAIL: 'DELETE_DEVICES_FAIL',
    DELETE_DEVICES_SUCCESS: 'DELETE_DEVICES_SUCCESS',
    RESET_DEVICES: 'RESET_DEVICES',
    RESET_DEVICES_FAIL: 'RESET_DEVICES_FAIL',
    RESET_DEVICES_SUCCESS: 'RESET_DEVICES_SUCCESS',
    UPDATE_CHANNEL: 'UPDATE_CHANNEL',
    UPDATE_CHANNEL_FAIL: 'UPDATE_CHANNEL_FAIL',
    UPDATE_CHANNEL_SUCCESS: 'UPDATE_CHANNEL_SUCCESS',
    GET_CHANNEL: 'GET_CHANNEL',
    GET_CHANNEL_FAIL: 'GET_CHANNEL_FAIL',
    GET_CHANNEL_SUCCESS: 'GET_CHANNEL_SUCCESS',
    RESET_DEVICE_MODELS: 'RESET_DEVICE_MODELS',
    RESET_SETUPS: 'RESET_SETUPS',
    GET_ORG_DEVICE_MODELS_SELECT: 'GET_ORG_DEVICE_MODELS_SELECT',
    GET_ORG_DEVICE_MODELS_SELECT_FAIL: 'GET_ORG_DEVICE_MODELS_SELECT_FAIL',
    GET_ORG_DEVICE_MODELS_SELECT_SUCCESS: 'GET_ORG_DEVICE_MODELS_SELECT_SUCCESS',
    UPDATE_DEVICES_FROM_WEBHOOK: 'UPDATE_DEVICES_FROM_WEBHOOK',
}

export function getSetups({ slug }) {
    return {
        type: actionTypes.GET_SETUP_LIST,
        payload: {
            request: {
                url: `api/teams/${slug}/ros/setups/`,
                method: 'GET',
            },
        },
    }
}

export function getOrgSetups({ slug, page, field = 'verbose_name', filters }) {
    let filterQuery = ''
    let setupType = ''

    if (filters && filters.team && filters.team.length > 0) {
        filterQuery = `team_slug=${filters.team}`
    }

    if (filters && filters.type && filters.type.length > 0) {
        setupType = `setup_type=${filters.type}`
    }

    const queryParams = [filterQuery, setupType].filter(Boolean).join('&')

    const url = `api/organizations/${slug}/ros/setups/?expand=device_model&ordering=${field}${
        page ? `&page=${page}` : ''
    }${queryParams ? `&${queryParams}` : ''}`

    return {
        type: actionTypes.GET_ORG_SETUP_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
            },
        },
    }
}

export function resetSetups() {
    return {
        type: actionTypes.RESET_SETUPS,
    }
}

export function createSetup({ data, slug }) {
    return {
        type: actionTypes.CREATE_SETUP,
        payload: {
            request: {
                url: `api/teams/${slug}/ros/setups/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createOrgSetup({ data, slug }) {
    return {
        type: actionTypes.CREATE_ORG_SETUP,
        payload: {
            request: {
                url: `api/organizations/${slug}/ros/setups/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateSetup({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_SETUP,
        payload: {
            request: {
                url: `api/setups/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function removeSetup({ uuid }) {
    return {
        type: actionTypes.REMOVE_SETUP,
        payload: {
            request: {
                url: `api/setups/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function getSetup({ uuid }) {
    return {
        type: actionTypes.GET_SETUP,
        payload: {
            request: {
                url: `api/setups/${uuid}/`,
                method: 'GET',
            },
        },
    }
}

export function createDeviceModel({ data, slug }) {
    return {
        type: actionTypes.CREATE_DEVICE_MODEL,
        payload: {
            request: {
                url: `api/v2/teams/${slug}/iot/device-models/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createOrgDeviceModel({ data, slug }) {
    return {
        type: actionTypes.CREATE_ORG_DEVICE_MODEL,
        payload: {
            request: {
                url: `api/v2/organizations/${slug}/iot/device-models/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getDeviceModels({ slug, text }) {
    return {
        type: actionTypes.GET_DEVICE_MODELS,
        payload: {
            request: {
                url: `api/v2/teams/${slug}/iot/device-models/?${text ? text : ''}`,
                method: 'GET',
            },
        },
    }
}

export function getOrgDeviceModels({ slug, page, field, filters }) {
    let teamQuery = ''
    let deviceModelTypeQuery = ''

    if (filters) {
        if (filters.team && filters.team.length > 0) {
            teamQuery = `team_slug=${filters.team}`
        }
        if (filters.device_model_type && filters.device_model_type.length > 0) {
            deviceModelTypeQuery = `device_model_type=${filters.device_model_type}`
        }
    }

    const queryParams = [teamQuery, deviceModelTypeQuery].filter(Boolean).join('&')

    const url = `api/v2/organizations/${slug}/iot/device-models/?detailed=true${
        field ? `&ordering=${field}` : ''
    }${page ? `&page=${page}` : ''}${queryParams ? `&${queryParams}` : ''}`

    return {
        type: actionTypes.GET_ORG_DEVICE_MODELS,
        payload: {
            request: {
                url,
                method: 'GET',
            },
        },
    }
}

export function getOrgDeviceModelsSelect({ slug }) {
    return {
        type: actionTypes.GET_ORG_DEVICE_MODELS_SELECT,
        payload: {
            request: {
                url: `api/v2/organizations/${slug}/iot/device-models/?no_pagination=true&light=true`,
                method: 'GET',
            },
        },
    }
}

export const resetDeviceModels = () => {
    return { type: actionTypes.RESET_DEVICE_MODELS }
}

export function updateDeviceModel({ data, uuid }) {
    return {
        type: actionTypes.UPDATE_DEVICE_MODEL,
        payload: {
            request: {
                url: `/api/v2/device-models/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function deleteDeviceModel(uuid) {
    return {
        type: actionTypes.DELETE_DEVICE_MODEL,
        payload: {
            request: {
                url: `/api/v2/device-models/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function createDevice({ data, uuid }) {
    return {
        type: actionTypes.CREATE_DEVICES,
        payload: {
            request: {
                url: `api/v2/indoors/area/${uuid}/iot/devices/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function udpateDevices({ data, uuid }) {
    return {
        type: actionTypes.CREATE_DEVICES,
        payload: {
            request: {
                url: `api/v2/indoors/area/${uuid}/iot/devices/`,
                method: 'PUT',
                data,
            },
        },
    }
}

export function getDevices({ slug }) {
    return {
        type: actionTypes.GET_DEVICES,
        payload: {
            request: {
                url: `api/v2/teams/${slug}/iot/devices/`,
                method: 'GET',
            },
        },
    }
}

export function updateDevice({ data, uuid }) {
    return {
        type: actionTypes.UPDATE_DEVICES,
        payload: {
            request: {
                url: `/api/v2/devices/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function deleteDevice(uuid) {
    return {
        type: actionTypes.DELETE_DEVICES,
        payload: {
            request: {
                url: `/api/v2/devices/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function resetDevices({ uuid }) {
    return {
        type: actionTypes.RESET_DEVICES,
        payload: {
            request: {
                url: `api/v2/indoors/area/${uuid}/iot/devices/reset/`,
                method: 'POST',
            },
        },
    }
}

export function updateChannel({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_CHANNEL,
        payload: {
            request: {
                url: `/api/v2/iot/channels/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function getChannel({ uuid, setupuuid }) {
    return {
        type: actionTypes.GET_CHANNEL,
        payload: {
            request: {
                url: `/api/v2/iot/channels/${uuid}/`,
                method: 'GET',
                setupuuid,
            },
        },
    }
}

export function updateDevicesFromWebhook(data) {
    return {
        type: actionTypes.UPDATE_DEVICES_FROM_WEBHOOK,
        payload: {
            data,
        },
    }
}
