import React from 'react'
import { Spinner } from 'reactstrap'

const LoadingSpinner = () => {
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            }}
        >
            <Spinner
                data-testid="loading"
                style={{
                    height: '4rem',
                    width: '4rem',
                }}
                color="meili"
            />
        </div>
    )
}

export default LoadingSpinner
